.buttons-modal-footer {
    display: flex;
    button {
        margin-left: 5px;
    }
    @media (max-width: 991px) {
        flex-direction: column;
        margin-left: 0;
        width: 100%;
        button {
            margin-bottom: 10px;
        }
    }
}

.contact-owner {
    display: flex;
    align-items: center;

    a {
        &:first-child {
            margin: 0 10px;
        }
    }
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: #efefef;
}

.progress-days {
    .progress-bar {
        animation: progress-animation-days 3s infinite;
    }
}

.progress-streets {
    .progress-bar {
        animation: progress-animation-streets 3.5s infinite;
    }
}

.progress-properties {
    .progress-bar {
        animation: progress-animation-properties 4s infinite;
    }
}

@keyframes progress-animation-days {
    0% {
        width: 10%;
    }
    25% {
        width: 30%;
    }
    50% {
        width: 50%;
    }
    75% {
        width: 75%;
    }
    100% {
        width: 95%;
    }
}

@keyframes progress-animation-streets {
    0% {
        width: 5%;
    }
    25% {
        width: 25%;
    }
    50% {
        width: 45%;
    }
    75% {
        width: 70%;
    }
    100% {
        width: 90%;
    }
}

@keyframes progress-animation-properties {
    0% {
        width: 0%;
    }
    25% {
        width: 20%;
    }
    50% {
        width: 40%;
    }
    75% {
        width: 65%;
    }
    100% {
        width: 85%;
    }
}

.buttons-modal-off-line {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 0 16px;
    margin: 0;

    @media (max-width: 991px) {
        width: calc(100% - 32px);
        margin: auto;
        padding: 0;
    }    
}

.buttons-modal-off-line-close {
    display: flex;
    padding: 0 16px;
    margin: 0;

    @media (max-width: 991px) {
        width: calc(100% - 32px);
        margin: auto;
        padding: 0;
    }    
}

.privacy-policies {
    p, li {
        font-size: 13px;
    }

    ol {
        padding: 0 16px;
        display: flex;
        flex-direction: column;
    }

    ul {
        padding: 8px 16px;
        list-style-type: disc;
    }
}
